<template>
  <v-navigation-drawer v-model="navDialog" app dark class="primary darken-2">
    <v-list>
      <v-row class="mt-7 mb-5">
        <v-img
          class="mx-auto"
          max-width="130"
          src="@/assets/img/logo.png"
        ></v-img>
      </v-row>

      <v-list-item-group color="secondary" class="my-5">
        <template v-for="(item, i) in items">
          <v-list-item
            v-if="viewPermissions(item.roles)"
            :key="i"
            :to="item.link"
            active-class="nav-active"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title small v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { viewPermissions } from '@/utils/roles'

export default {
  data: () => ({
    dialog: false,
    drawer: null,
    items: [
      {
        icon: 'mdi-view-dashboard',
        text: 'Dashboard',
        link: '/dashboard',
        roles: [],
      },
      {
        icon: 'mdi-account-cog',
        text: 'Management',
        link: '/management',
        roles: ['admin'],
      },
      {
        icon: 'mdi-account-hard-hat',
        text: 'Service Providers',
        link: { name: 'ServiceProvider' },
        roles: ['admin'],
      },
      {
        icon: 'mdi-text-box-check-outline',
        text: 'Region Management',
        link: '/regions',
        roles: ['admin'],
      },
      {
        icon: 'mdi-car',
        text: 'Assessment center',
        link: { name: 'AssessmentCenter' },
        roles: [
          'internalAssessor',
          'externalAssessor',
          'technician',
          'claimOfficer',
        ],
      },
      {
        icon: 'mdi-timer-outline',
        text: 'TAT Reports',
        link: '/tat-reports',
        roles: [
          'admin',
          'claimOfficer',
          'internalAssessor',
          'sectionHead',
          'technician',
        ],
      },
    ],
    navDialog: true,
  }),
  created() {
    this.$root.$on('open-close-nav', () => {
      this.navDialog = !this.navDialog
    })
  },
  methods: {
    viewPermissions,
  },
}
</script>

<style scoped>
.nav-active {
  border-left: #ff5252 8px solid;
}
</style>
